/* Tous les dictionnaires */


export const mask = {
    siren: {
        mask: "000 000 000", lazy: false //affiche les _
    }, capitalUnsigned: {
        mask: 'num', blocks: {
            num: {
                mask: Number, thousandsSeparator: ' ', signed: false,  // disallow negative
                normalizeZeros: false,  // appends or removes zeros at ends
                scale: 2,  // digits after point, 0 for integers
            }
        }
    }, capitalSigned: {
        mask: 'num', blocks: {
            num: {
                mask: Number, thousandsSeparator: ' ', signed: true,  // disallow negative
                normalizeZeros: false,  // appends or removes zeros at ends
                scale: 2,  // digits after point, 0 for integers
            }
        }
    }
};

export const def = {
    emptyInput: {name: "", label: "", type: "hidden", validation: "", validationMessages: {}},

    /* Text */
    activiteStr: {name: "activiteStr", label: "Activité", type: "text"},
    notaire: {name: "notaire", label: "Notaire", type: "text"},
    representerPar: {name: "representerPar", label: "Représenté par", type: "text"},
    enRemplacementDe: {name: "enRemplacementDe", label: "En remplacement de", type: "text"},
    officiantA: {name: "officiantA", label: "Officiant à", type: "text"},
    denomination: {name: "denomination", label: "Dénomination", type: "text"},
    dossier: {name: "dossier", label: "Dossier", type: "text"},
    reference: {name: "reference", label: "Référence", type: "text"},
    journalPublication: {name: "journalPublication", label: "Journal de publication", type: "text"},
    formaliteAuRcs: {name: "formaliteAuRcs", label: "Formalité au RCS", type: "text"},
    sigle: {name: "sigle", label: "Sigle", type: "text"},
    nomSociete: {name: "nomSociete", label: "Nom Societe", type: "text"},
    nomCommercial: {name: "nomCommercial", label: "Nom Commercial", type: "text"},
    nom: {name: "nom", label: "Nom", type: "text"},
    nomNotaire: {name: "nomNotaire", label: "Nom notaire", type: "text"},
    prenom: {name: "prenom", label: "Prénom", type: "text"},
    adresse: {name: "adresse", label: "Adresse", type: "text"},
    adresseSuite: {name: "adresseSuite", label: "Adresse Suite", type: "text"},
    codePostal: {name: "codePostal", label: "Code postal", type: "text"},
    villeStr: {name: "villeStr", label: "Ville", type: "text"},
    villeEnregistrement: {name: "villeEnregistrement", label: "Ville d'enregistrement", type: "text"},
    departementStr: {name: "departementStr", label: "Département", type: "text"},
    formeJuridiqueStr: {name: "formeJuridiqueStr", label: "Forme juridique", type: "text"},
    pays: {name: "pays", label: "Pays", type: "text"},
    greffe: {name: "greffe", label: "Greffe", type: "text"},
    rcs: {name: "rcs", label: "RCS", type: "text"},
    recuPar: {name: "recuPar", label: "Reçu par Me", type: "text"},
    nouveauPatronyme: {name: "nouveauPatronyme", label: "Nouveau patronyme", type: "text"},
    "greffe-rcs": {name: "greffe-rcs", label: "Greffe / RCS", type: "text"},
    lieuImmatriculation: {name: "lieuImmatriculation", label: "Lieu d'Immatriculation (RCS)", type: "text"},
    lieuNaissance: {name: "lieuNaissance", label: "Lieu de naissance", type: "text"},
    lieuActe: {name: "lieuActe", label: "Lieu", type: "text"},
    lieuNotaire: {name: "lieuNotaire", label: "Lieu notaire", type: "text"},
    lieuNaissanceCp: {name: "lieuNaissanceCp", label: "CP lieu de naissance", type: "text"},


    /* Number */
    duree: {name: "duree", label: "Durée", type: "number", min: "0"},
    montantAugmentation: {name: "montantAugmentation", label: "Montant de l'augmentation (Euros)", type: "number"},
    montantReduction: {name: "montantReduction", label: "Montant de la réduction(Euros)", type: "number"},
    montantDiminution: {name: "montantDiminution", label: "Montant de la diminution (Euros)", type: "number"},
    montantModification: {name: "montantModification", label: "Montant de la modification (Euros)", type: "text", vfeMask: mask.capitalSigned},


    /* Date */
    date: {name: "date", label: "Date", type: "date"},
    dateActe: {name: "dateActe", label: "Date de l'acte", type: "date"},
    dateAGE: {name: "dateAGE", label: "AGE en date du ", type: "date"},
    dateAG: {name: "dateAG", label: "Date de l'AG", type: "date"},
    dateEffet: {name: "dateEffet", label: "Date de l'effet", type: "date"},
    dateDu: {name: "dateDu", label: "En date du", type: "date"},
    datePublication: {name: "dateEffet", label: "Date de publication", type: "date"},
    dateEnregistrement: {name: "dateEnregistrement", label: "Date enregistrement", type: "date"},
    changementACompterDu: {name: "changementACompterDu", label: "Changement à compter du", type: "date"},
    aCompterDu: {name: "aCompterDu", label: "à compter du", type: "date"},
    dateNaissance: {name: "dateNaissance", label: "Date de naissance", type: "date"},
    dateEntree: {name: "dateEntree", label: "Date d'entrée'", type: "date"},
    dateDebut: {name: "dateDebut", label: "Date de début'", type: "date"},


    /* Mask */
    montant: {name: "montant", label: "Montant", type: "text", vfeMask: mask.capitalSigned},
    montantCapital: {name: "montantCapital", label: "Montant du Capital", type: "text", vfeMask: mask.capitalSigned},
    montantAction: {name: "montantAction", label: "Montant actions", type: "text", vfeMask: mask.capitalSigned},
    nouveauCapital: {name: "nouveauCapital", label: "Nouveau Capital (Euros)", type: "text", vfeMask: mask.capitalSigned},
    capitalInitial: {name: "capitalInitial", label: "Initial", type: "text", vfeMask: mask.capitalUnsigned},
    capitalMinimum: {name: "capitalMinimum", label: "Minimum", type: "text", vfeMask: mask.capitalUnsigned},
    siren: {name: "siren", label: "SIREN", type: "text", vfeMask: mask.siren},


    /* TextArea */
    objet: {name: "objet", label: "Objet", type: "textarea", rows: "7"},
    activite: {name: "activite", label: "Activité", type: "textarea", rows: "7"},
    opposition: {name: "opposition", label: "Oppositions", type: "textarea", rows: "7"},
    modeSessionPartsSocial: {name: "modeSessionPartsSocial", type: "textarea", rows: "7"},
    additif: {name: "additif", type: "textarea", rows: "7"},
    autre: {name: "autre", type: "textarea", rows: "7"},
    indiquez: {name: "indiquez", type: "textarea", rows: "7"},
    indiquez_1: {name: "indiquez_1", label: '', type: "textarea", rows: "7"},
    indiquez_2: {name: "indiquez_2", label: '', type: "textarea", rows: "7"},
    administrateur: {name: "administrateur", label: '(Indiquer les administrateurs à la suite)\nPour une personne physique: nom, prénom, adresse, cp, ville\nPour une société: forme, dénomination, capital, siège(adresse, cp, ville), RCS(ville et N°) ',type: "textarea", rows: "7"},


    /* checkbox */
    nominationCommissaireAuxComptes: {name: "nominationCommissaireAuxComptes", label: "Nomination Commissaires aux comptes", type: "checkbox", "label-position": "before"},
    nominationDirecteurGeneral: {name: "nominationDirecteurGeneral", label: "Nomination d'un Directeur Général", type: "checkbox", "label-position": "before"},


    /* Radio */
    typePersonne: {
        name: "typePersonne",
        label: "",
        type: "radio",
        value: 'physique',
        options: [
            {value: 'physique', label: 'Personne physique'},
            {value: 'morale', label: 'Personne morale'}
        ],
    },
    typePersonneOuAucun: {
        name: "typePersonneOuAucun",
        label: "",
        type: "radio",
        value: 'physique',
        options: [
            {value: 'physique', label: 'Personne physique'},
            {value: 'morale', label: 'Personne morale'},
            {value: 'autre', label: 'autre'}
        ],
    },
    typeOpposition: {
        name: "typeOpposition",
        label: "",
        type: "radio",
        value: '1',
        options: [
            {value: '1', label: "Les oppositions, s'il y a lieu, seront reçues au fonds vendu, dans les dix jours de la dernière en date des publications légales"},
            {value: 'autre', label: 'Autre'}
        ],
        values: {
            "1": "Les oppositions, s'il y a lieu, seront reçues au fonds vendu, dans les dix jours de la dernière en date des publications légales"
        }
    },
    typeSuppleant: {
        name: "typeSuppleant",
        label: "Suppleant",
        type: "radio",
        value: 'physique',
        options: [
            {value: 'physique', label: 'Personne physique'},
            {value: 'morale', label: 'Personne morale'},
            {value: 'aucun', label: 'Aucun'}
        ],
    },
    typeTitulaire: {
        name: "typeTitulaire",
        label: "Titulaire",
        type: "radio",
        value: 'physique',
        options: [
            {value: 'physique', label: 'Personne physique'},
            {value: 'morale', label: 'Personne morale'}
        ],
    },
    typeModification: {
        name: "typeModification",
        label: "",
        type: "radio",
        value: 'augmenté',
        options: [
            {value: 'augmenté', label: 'Augmentation'},
            {value: 'réduit', label: 'Réduction'},
        ],
    },
    typeCapital: {
        name: "typeCapital",
        label: "",
        type: "radio",
        value: 'fixe',
        options: [{value: 'fixe', label: 'Fixe'}, {value: 'variable', label: 'Variable'}],
    },
    typeAdmission: {
        name: "typeAdmission", label: "Texte proposé. A comparer avec les mentions figurant dans vos statuts", type: "radio",
        value: '1',
        options: [
            {
            value: '1',
            label: 'Tout actionnaire peut participer aux assemblées sur justification de son identité et de l\'inscription en compte de ses actions : chaque action donne droit à une voix'
        },
            {value: 'autre', label: 'autre'},],
        values: {
            "1": "Tout actionnaire peut participer aux assemblées sur justification de son identité et de l'inscription en compte de ses actions : chaque action donne droit à une voix",
        },
    },
    typeTransmissionAction: {
        name: "typeTransmissionAction", label: "Texte proposé. A comparer avec les mentions figurant dans vos statuts", type: "radio",
        value: '1',
        options: [{
            value: '1',
            label: 'Les actions sont librement cessibles entre actionnaires, toutes autres cessions et transmissions sont soumises à l\'agrément du conseil d\'administration.'
        }, {value: 'autre', label: 'autre'},],
        values: {
            "1": "Les actions sont librement cessibles entre actionnaires, toutes autres cessions et transmissions sont soumises à l'agrément du conseil d'administration.",
        }
    },
    typeCessionPart: {
        name: "typeCessionPart", label: 'Texte proposé. A comparer avec les mentions figurant dans vos statuts', type: 'radio',
        value: '1',
        options: [{
            value: '1',
            label: 'Les actions sont librement cessibles entre actionnaires, toutes autres cessions et transmissions sont soumises à l\'agrément du conseil d\'administration.'
        }, {
            value: 'autre', label: 'autre'
        }],
        values: {'1': "Les actions sont librement cessibles entre actionnaires, toutes autres cessions et transmissions sont soumises à l'agrément du conseil d'administration."}
    },


    /* Select  */
    natureActe: {
        name: "natureActe", label: "Type acte", type: "select", value: '',
        options: {
            "": "Choisissez",
            "acte SSP": "Acte sous seing privé",
            "acte authentique": "Acte authentique",
        },
    },
    acteRegimeActuel: {
        name: "acteRegimeActuel", label: "Régime actuel", type: "select", value: '',
        options: {
            "": "Choisissez",
            "cra": "Communauté réduite aux acquêts",
            "cu": "Communauté universelle",
            "sb": "Séparation de biens",
            "pa": "Participation aux acquêts",
        },
        values: {
            "cra": "Communauté réduite aux acquêts",
            "cu": "Communauté universelle",
            "sb": "Séparation de biens",
            "pa": "Participation aux acquêts",
        }
    },
    acteNouveauRegime: {
        name: "acteNouveauRegime", label: "Nouveau régime", type: "select", value: '',
        options: {
            "": "Choisissez",
            "cra": "Communauté réduite aux acquêts",
            "cu": "Communauté universelle",
            "sb": "Séparation de biens",
            "pa": "Participation aux acquêts",
        },
        values: {
            "cra": "Communauté réduite aux acquêts",
            "cu": "Communauté universelle",
            "sb": "Séparation de biens",
            "pa": "Participation aux acquêts",
        }
    },
    auTermeAssembleDecisionActe: {
        name: "auTermeAssembleDecisionActe", label: "Aux termes", type: "select", value: '',
        options: [
            {value: '',  label: 'Choisissez'},
            {value: '1', label: 'd\'une assemblée générale ordinaire'},
            {value: '2', label: 'd\'une assemblée générale extraordinaire'},
            {value: '3', label: 'd\'une assemblée générale mixte'},
            {value: '4', label: 'd\'une décision de l\'associé unique'},
            {value: '5', label: 'd\'une décision de la gérance'},
            {value: '6', label: 'd\'un acte reçu par Me'},
            {value: '7', label: 'd\'une décision de la présidence'},
        ],
        values: {
            "1": "d'une assemblée générale ordinaire",
            "2": "d'une assemblée générale extraordinaire",
            "3": "d'une assemblée générale mixte",
            "4": "d'une décision de l'associé unique",
            "5": "d'une décision de la gérance",
            "6": "d'un acte reçu par Me",
            "7": "d'une décision de la présidence",
        }
    },
    auTermeAssembleDecision: {
        name: "auTermeAssembleDecision", label: "Aux termes", type: "select", value: '',
        options: [
            {value: '', label: "Choisissez"},
            {value: '1', label: "Assemblée générale ordinaire"},
            {value: '2', label: "Assemblée générale extraordinaire"},
            {value: '3', label: "Assemblée générale mixte"},
            {value: '4', label: "Décision de l'associé unique"},
            {value: '5', label: "Décision de la gérance"},
            {value: '7', label: "Décision du conseil d'administration"},
        ],
        values: {
            "1": "assemblée générale ordinaire",
            "2": "assemblée générale extraordinaire",
            "3": "assemblée générale mixte",
            "4": "décision de l'associé unique",
            "5": "décision de la gérance",
            "7": "décision du conseil d'administration",
        }
    },
    formeJuridique: {
        name: "formeJuridique",
        label: "Forme juridique",
        type: "select",
        value: '',
        options: [{value: "", label: "Choisissez la forme juridique"}, {
            value: "SARL",
            label: "Société à responsabilité limitée (SARL)"
        }, {
            value: "SARL a capital variable",
            label: "Société à responsabilité limitée à capital variable"
        }, {value: "SARLU", label: "Société à responsabilité limitée à associé unique (SARLU)"},],
        values: {
            "SARL": "Société à responsabilité limitée",
            "SARLU": "Société à responsabilité limitée à associé unique (SARLU)",
        }
    },
    formesSocietes: {
        name: "formesSocietes",
        label: "Forme",
        type: "select",
        value: '',
        options: [
            {value: "",         label: "Choisissez"},
            {value: "sa",       label: "Société anonyme"},
            {value: "sad",      label: "Société anonyme à directoire"},
            {value: "saca",     label: "Société anonyme à conseil d'administration"},
            {value: "saemd",    label: "Société anonyme d'économie mixte à directoire"},
            {value: "saemcd",   label: "Société anonyme d'économie mixte à conseil d'administration"},
            {value: "sahd",     label: "Société anonyme de HLM à directoire"},
            {value: "sahca",    label: "Société anonyme de HLM à conseil d'administration"},
            {value: "sarl",     label: "Société à responsabilité limité"},
            {value: "sarlau",   label: "Société à responsabilité limitée à associé unique"},
            {value: "sarlu",    label: "Société à responsabilité limitée unipersonnelle"},
            {value: "sas",      label: "Société par action simplifiée"},
            {value: "sasau",    label: "Société par action simplifiée à associé unique"},
            {value: "sc",       label: "Société civile"},
            {value: "sci",      label: "Société civile immobilière"},
            {value: "scm",      label: "Société civile de moyens"},
            {value: "scp",      label: "Société civile professionnelle"},
            {value: "scea",     label: "Société civile d'exploitation agricole"},
            {value: "selrl",    label: "Société d'exercice libéral à responsabilité limitée"},
            {value: "selas",    label: "Société d'exercice libéral par action simplifiée"},
            {value: "selfa",    label: "Société d'exercice libéral à forme anonyme"},
            {value: "snc",      label: "Société en nom collectif"},
            {value: "ste",      label: "Société technique équipement"},
            {value: "scorl",    label: "Société coopérative ouvrière à responsabilité limitée"},

        ],
        values: {
            "sa":       "Société anonyme",
            "sad":      "Société anonyme à directoire",
            "saca":     "Société anonyme à conseil d'administration",
            "saemd":    "Société anonyme d'économie mixte à directoire",
            "saemcd":   "Société anonyme d'économie mixte à conseil d'administration",
            "sahd":     "Société anonyme de HLM à directoire",
            "sahca":    "Société anonyme de HLM à conseil d'administration",
            "sarl":     "Société à responsabilité limité",
            "sarlau":   "Société à responsabilité limitée à associé unique",
            "sarlu":    "Société à responsabilité limitée unipersonnelle",
            "sas":      "Société par action simplifiée",
            "sasau":    "Société par action simplifiée à associé unique",
            "sc":       "Société civile",
            "sci":      "Société civile immobilière",
            "scm":      "Société civile de moyens",
            "scp":      "Société civile professionnelle",
            "scea":     "Société civile d'exploitation agricole",
            "selrl":    "Société d'exercice libéral à responsabilité limitée",
            "selas":    "Société d'exercice libéral par action simplifiée",
            "selfa":    "Société d'exercice libéral à forme anonyme",
            "snc":      "Société en nom collectif"
        },
    },
    formeSocieteSarl: {
        name: "formeSocieteSarl",
        label: "Forme",
        type: "select",
        value: '',
        options: [
            {value: "", label: "Choisissez la forme"},
            {value: "SARL", label: "Société à responsabilité limité"},
            {value: "SARL a associe unique", label: "SARL à associé unique."},
        ],
        values: {
            "SARL a associe unique": "SARL à associé unique",
        },
    },
    formeSocieteSnc: {
        name: "formeSocieteSnc",
        label: "Forme",
        type: "select",
        value: 'SNC',
        options: [
            {value: "SNC", label: "SNC"},
        ],
        disabled: true,
    },
    formeSocieteSa: {
        name: "formeSocieteSa",
        label: "Forme",
        type: "select",
        value: '',
        options: [
            {value: "", label: "Choisissez la forme"},
            {value: "SA", label: "Société anonyme"},
            {value: "sad", label: "Société anonyme à directoire"},
            {value: "saca", label: "Société anonyme à conseil d'administration"},
            {value: "saemd", label: "Société anonyme d'économie mixte à directoire"},
            {value: "saemcd", label: "Société anonyme d'économie mixte à conseil d'administration"},
            {value: "sahd", label: "Société anonyme de HLM à directoire"},
            {value: "sahca", label: "Société anonyme de HLM à conseil d'administration"},
        ],
        values: {
            "sad": "SA à directoire",
            "saca": "SA à conseil d'administration",
            "saemd": "SA d'économie mixte à directoire",
            "saemcd": "SA d'économie mixte à conseil d'administration",
            "sahd": "SA de HLM à directoire",
            "sahca": "SA de HLM à conseil d'administration",
        },
    },
    formeSocieteSc: {
        name: "formeSocieteSc",
        label: "Forme",
        type: "select",
        value: '',
        options: [
            {value: "", label: "Choisissez la forme"},
            {value: "SC", label: "Société civile"},
            {value: "sci", label: "Société civile immobilière"},
            {value: "scm", label: "Société civile de moyens"},
            {value: "scp", label: "Société civile professionnelle"},
            {value: "sccv", label: "Société civile de construction-vente"},
        ],
        values: {
            "sci": "SC immobilière",
            "scm": "SC de moyens",
            "scp": "SC professionnelle",
            "sccv": "SC de construction-vente",
        },
    },
    formeSocieteSas: {
        name: "formeSocieteSas",
        label: "Forme",
        type: "select",
        value: '',
        options: [
            {value: "", label: "Choisissez la forme"},
            {value: "SAS", label: "Société par action simplifié"},
            {value: "SAS a associe unique", label: "Société par action simplifié à associé unique"},
        ],
        values: {
            "SAS a associe unique": "SAS a associe unique",
        },
    },
    siegeLiquidation: {
        name: "siegeLiquidation",
        label: "Siège de la liquidation",
        type: "select",
        value: '',
        options: [
            {value: "", label: "Choisissez la forme"},
            {value: "1", label: "Au siège social"},
            {value: "2", label: "A l'adresse du liquidateur"},
        ],
        values: {
            "1": "au siège social",
            "2": "à l'adresse du liquidateur",
        },
    },
    ville: {
        name: "ville",
        label: "ville",
        type: "select",
        value: '',
        options: [{value: "", label: "Choisissez"}],
    },
    civilite: {
        name: "civilite",
        label: "Civilité",
        type: "select",
        value: '',
        options: [
            {value: "", label: "Choisissez"},
            {value: "homme", label: "Homme"},
            {value: "femme", label: "Femme"},],
    },
    residenceEtranger: {
        name: "residenceEtranger",
        label: "Résidence en étranger",
        type: "select",
        value: '',
        options: [{value: "", label: "Choisissez"}, {value: "non", label: "Non"}, {value: "oui", label: "Oui"},],
    },
    saisirDonnee: {
        name: "saisirDonnee",
        label: "Saisir données",
        type: "select",
        value: '',
        options: [{value: "", label: "Choisissez"}, {value: "non", label: "Non"}, {value: "oui", label: "Oui"},],
    },
    departement: {
        name: "departement",
        label: "Département",
        type: "select",
        value: '',
        options: [{value: "", label: "Choisissez un département de publication"}, {
            value: "01 - Ain",
            label: "01 - Ain"
        }, {value: "02 - Aisne", label: "02 - Aisne"}, {value: "03 - Allier", label: "03 - Allier"},],
    },
    statusAcquereur: {
        name: "statusAcquereur", label: "Statut acquéreur", type: "select", value: '',
        options: [
            {value: '', label: "Choisissez"},
            {value: '1', label: "Personne physique déjà immatriculée au RCS"},
            {value: '2', label: "Personne physique non inscrite au RCS"},
            {value: '3', label: "Société en cours d'immatriculation"},
            {value: '4', label: "Société déjà immatriculée"},
        ],
        values: {
            "1": "Personne physique déjà immatriculée au RCS",
            "2": "Personne physique non inscrite au RCS",
            "3": "Société en cours d'immatriculation",
            "4": "Société déjà immatriculée",
        }
    },
    typeRenouvellement: {
        name: "typeRenouvellement", label: "Type renouvellement", type: "select", value: '',
        options: [
            {value: '', label: "Choisissez"},
            {value: '1', label: "Renouvellement par tacide reconduction"},
            {value: '2', label: "Sans possibilité de reconduction"},
        ],
        values: {
            "1": "renouvellement par tacide reconduction",
            "2": "sans possibilité de reconduction",
        }
    },
    typeDuree: {
        name: "typeDuree", label: "", type: "select", value: '',
        options: [
            {value: '', label: "Choisissez"},
            {value: 'mois', label: "Mois"},
            {value: 'ans', label: "Ans"},
        ],
    },
    typeRepresentant: {
        name: "typeRepresentant", label: "", type: "select", value: '',
        options: [
            {value: '', label: "Choisissez"},
            {value: 'Gérant', label: "Gérant"},
            {value: 'Président', label: "Président"},
            {value: 'Directeur Général', label: "Directeur Général"},
        ],
    },
    conformementArticle: {
        name: "conformementArticle", label: "Conformément à l'article", type: "select", value: '',
        options: [
            {value: '', label: "Choisissez"},
            {value: 'L223-42', label: "L223-42 (SARL)"},
            {value: 'L225-248', label: "L225-248 (SA)"},
            {value: 'L225-248 et 277.1', label: "L225-248 et 277.1 (SAS)"},
        ],
    },
};

export const group = {
        // statusGroup: {
    //     name: "statusGroup",
    //     label: "Les statuts",
    //     type: "group",
    //     value: [{}],
    //     children: [
    //         def.typeActe, {
    //             on: {
    //                 input: (val) => {
    //                     ['sarl'].schema[0].children[findChildrenInSchema(sarl.schema[0], 'recuPar')] = val === "acte authentique" ? visible(def.recuPar) : hidden(def.recuPar);
    //                 }
    //             }
    //         }
    //         def.dateActe,
    //         def.lieuActe,
    //         hidden(def.recuPar
    //     ],
    // },
};
/*

{
    //0
    name: "societyGroup",
    label: "La société",
    type: "group",
    value: [{}],
    children: []
},
{
    //2
    name: "acteGroup",
    label: "Acte",
    type: "group",
    value: [{}],
    children: []
},
{
    //2
    name: "siegeSocialGroup",
    label: "Siège social",
    type: "group",
    value: [{}],
    children: [
        newInput(def.adresse, '_required'),
        newInput(def.adresseSuite),
        newInput(def.codePostal, '_required', {
            on: {
                change: async (val) => {
                    val = val.target.value;
                    if (val.length !== 5) {
                        return false;
                    }

                    const data = await getDataByCodePostal(val);

                    if(Object.keys(data).length === 0) {
                        return false;
                    }

                    snc.schema[2].children[3].options = data.options;
                    snc.model.siegeSocialGroup[0].ville = data.ville_slug;
                    snc.model.siegeSocialGroup[0].lieuImmatriculation = data.greffe;
                }
            }
        }),
        newInput(def.ville, '_validUniqueSelect'),
        newInput(def.lieuImmatriculation, '_required', {}, 'disabled'),
    ]
},
{
    //3
    name: "liquidateur",
    label: "Liquidateur",
    type: "group",
    value: [{}],
    children: []
},
*/
